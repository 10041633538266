import React from 'react';
import Hero from '../Hero';
import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import AwardSectionStyle3 from '../Section/AwardSection/AwardSectionStyle3';
import Banner from '../Section/BannerSection';
import Section from '../Section';
import FeaturesSection from '../Section/FeaturesSection';

import { pageTitle } from '../../helpers/PageTitle';
import ContactForm from '../ContactForm';
import ContactInfoSection from '../Section/ContactInfoSection';
import SectionHeading from '../SectionHeading';


const featureListData = [

  {
    // iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5rem' height='1.5rem' viewBox='0 0 2048 2048'%3E%3Cpath fill='%23000' d='M1728 640q66 0 124 25t101 69t69 102t26 124q0 57-19 109t-53 93t-81 71t-103 41v102q0 89-22 173t-62 160t-98 137t-129 107t-155 70t-174 25q-91 0-174-25t-154-70t-129-107t-98-137t-63-159t-22-174v-229q-123-11-218-59T133 962T34 781T0 558V0h320q26 0 45 19t19 45t-19 45t-45 19H128v430q0 106 29 192t87 147t140 94t192 33q101 0 184-31t141-89t91-140t32-185V128H832q-26 0-45-19t-19-45t19-45t45-19h320v558q0 120-34 223t-99 181t-160 126t-219 59v229q0 107 38 205t107 174t162 120t205 45q111 0 204-45t162-120t107-173t39-206v-102q-56-12-103-41t-81-70t-53-94t-19-109q0-66 25-124t68-101t102-69t125-26m0 512q40 0 75-15t61-41t41-61t15-75t-15-75t-41-61t-61-41t-75-15t-75 15t-61 41t-41 61t-15 75t15 75t41 61t61 41t75 15'/%3E%3C/svg%3E",
    // iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.2rem' height='1.2rem' viewBox='0 0 64 64'%3E%3Cpath fill='%23000' d='M15.111 8.293V1.618H8.436v6.675H1.761v6.675h6.675v6.674h6.675v-6.674h6.675V8.293zm21.065 20.719c4.605 0 8.341-3.739 8.341-8.343a8.34 8.34 0 0 0-8.341-8.34a8.34 8.34 0 0 0-8.341 8.34c0 4.603 3.736 8.343 8.341 8.343m-13.065 34.51V56.12l-7.423 2.066a4.735 4.735 0 0 1-1.755.338a4.757 4.757 0 0 1-3.942-7.414l11.907-16.327c1.293-1.77 3.098-3.743 6.905-3.743h14.395c3.805 0 5.612 1.973 6.903 3.743L62.008 51.11a4.758 4.758 0 0 1-3.94 7.414a4.727 4.727 0 0 1-1.755-.338l-7.425-2.066v7.402zM36 58.351l9.619-3.183l-.217-.06c-6.636-1.953-4.052-10.721 2.583-8.763l.907.305v-9.902L36 40.96l-12.891-4.212v9.902l.902-.305c6.64-1.958 9.224 6.81 2.584 8.763l-.213.06z'/%3E%3C/svg%3E",
    iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5rem' height='1.5rem' viewBox='-2 -2 24 24'%3E%3Cpath fill='%23000' d='M7 12.917v.583a4.5 4.5 0 1 0 9 0v-1.67a3.001 3.001 0 1 1 2 0v1.67a6.5 6.5 0 1 1-13 0v-.583A6.002 6.002 0 0 1 0 7V2a2 2 0 0 1 2-2h1a1 1 0 1 1 0 2H2v5a4 4 0 1 0 8 0V2H9a1 1 0 1 1 0-2h1a2 2 0 0 1 2 2v5a6.002 6.002 0 0 1-5 5.917M17 10a1 1 0 1 0 0-2a1 1 0 0 0 0 2'/%3E%3C/svg%3E",
    title: 'Tıbbi Danışmanlık',
    subTitle:
      'Uzman doktorlarımızdan tıbbi konularda danışmanlık alabilir, sağlık durumunuz hakkında bilgi edinebilirsiniz.',
  },
  {
    iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.2rem' height='1.2rem' viewBox='0 0 14 14'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M8 .5H1.5a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V8m-11 2.5h11M3.5 3h2m-2 2.5h1'/%3E%3Cpath d='m8.994 7.506l-3 .54l.5-3.04l4.23-4.21a1 1 0 0 1 1.42 0l1.06 1.06a.998.998 0 0 1 0 1.42z'/%3E%3C/g%3E%3C/svg%3E",
    title: 'Reçete',
    subTitle:
      'Görüşme sonrası gerekli görülen durumlarda reçete hizmetimizle ilaçlarınıza kolayca ulaşabilirsiniz.',
  },
  {
    iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5rem' height='1.5rem' viewBox='0 0 14 14'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9.5 1.5H11a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h1.5'/%3E%3Cpath d='M8.5.5h-3a1 1 0 0 0-1 1V2a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-.5a1 1 0 0 0-1-1M6.303 5.21a.43.43 0 0 0-.43.43v1.207H4.666a.43.43 0 0 0-.43.43v1.395c0 .237.193.43.43.43h1.207v1.207c0 .237.193.43.43.43h1.395a.43.43 0 0 0 .43-.43V9.1h1.207a.43.43 0 0 0 .43-.43V7.277a.43.43 0 0 0-.43-.429H8.128V5.639a.43.43 0 0 0-.43-.429z'/%3E%3C/g%3E%3C/svg%3E",
    title: 'Hastalık İzni',
    subTitle: `Muayene sonuçlarınıza göre hastalık izin raporları düzenleyebiliriz.`,

  },
  {
    
    iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5rem' height='1.5rem' viewBox='0 0 26 26'%3E%3Cpath fill='%23000' d='M13 0c-2.1 0-3.357.32-4.156.719c-.4.2-.684.41-.875.625a2 2 0 0 0-.313.531a1 1 0 0 0-.062.25l-.532 6.844c-.007.042.007.11 0 .156L7 9.813A1.003 1.003 0 0 0 7 10c0 3.3 2.7 6 6 6s6-2.7 6-6v-.156a1.003 1.003 0 0 0 0-.031l-.594-7.688a1 1 0 0 0-.062-.25s-.121-.316-.313-.531c-.191-.216-.475-.426-.875-.625C16.357.319 15.1 0 13 0m0 16c-6.6 0-12 3.106-12 5.906V26h24v-4.094c0-2.66-4.882-5.59-11.031-5.875A14.294 14.294 0 0 0 13 16m0-14c1.9 0 2.849.3 3.25.5c.134.067.15.093.188.125l.406 5.125C15.924 7.806 14.67 8 13 8s-2.923-.194-3.844-.25l.406-5.125c.037-.032.054-.058.188-.125c.401-.2 1.35-.5 3.25-.5m-.813 1v1.188H11v1.625h1.188V7h1.624V5.812H15V4.188h-1.188V3zM10 18.25L12.563 24H3v-2.094c0-.745 2.55-2.927 7-3.656m6 0c4.45.73 7 2.911 7 3.656V24h-9.563z'/%3E%3C/svg%3E",
    title: 'Konsultasyon',
    subTitle:
      'Çözülemeyen sağlık problerinizi alanında uzman doktorlarımızla detaylı değerlendirebilirsiniz.',
  },
  {
    iconSrc: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 64 64'%3E%3Cpath fill='%23000' d='M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2m-3.117 15.25c4.381 0 8.311 1.955 10.984 5.049a11.527 11.527 0 0 0-6.638-2.099c-6.444 0-11.668 5.283-11.668 11.8s5.224 11.8 11.668 11.8c2.466 0 4.753-.775 6.638-2.097c-2.674 3.092-6.604 5.047-10.984 5.047C20.828 46.75 14.3 40.147 14.3 32c0-8.146 6.528-14.75 14.583-14.75M49.7 36.254l-5.263-1.638l-3.246 4.268l.012-5.267L35.934 32l5.27-1.617l-.012-5.266l3.246 4.267l5.263-1.638L46.436 32z'/%3E%3C/svg%3E",
    title: "Türkiye'de Tedavi Organizasyonu",
    subTitle:"Kaliteli kliniklerde, iyi hekimlere, uygun fiyata tedavi olabilirsiniz.",
  },
];

const awardData = [
  {
    iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 576 512'%3E%3Cpath fill='white' d='M224 24v56h-56c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h56v56c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-56h56c13.3 0 24-10.7 24-24v-48c0-13.3-10.7-24-24-24h-56V24c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24m335.7 368.2c17.8-13.1 21.6-38.1 8.5-55.9s-38.1-21.6-55.9-8.5L392.6 416H272c-8.8 0-16-7.2-16-16s7.2-16 16-16h80c17.7 0 32-14.3 32-32s-14.3-32-32-32H193.7c-29.1 0-57.3 9.9-80 28l-44.9 36H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32h320.5c29 0 57.3-9.3 80.7-26.5l126.6-93.3zm-367-8.2h.9z'/%3E%3C/svg%3E",
    title: 'Danışma 60 zł',
  },
  { iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 48 48'%3E%3Cg fill='none' stroke='white' stroke-width='4'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M23 42H9a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v11.5'/%3E%3Cpath fill='white' stroke-linejoin='round' d='M36.636 27C39.046 27 41 28.88 41 31.2c0 3.02-2.91 5.6-4.364 7c-.97.933-2.181 1.867-3.636 2.8c-1.454-.933-2.667-1.867-3.636-2.8c-1.455-1.4-4.364-3.98-4.364-7c0-2.32 1.954-4.2 4.364-4.2c1.517 0 2.854.746 3.636 1.878A4.406 4.406 0 0 1 36.636 27Z'/%3E%3Cpath stroke-linecap='round' d='M15 14h16'/%3E%3C/g%3E%3C/svg%3E",
  title: 'Sadece Reçete 70 zł' },
  {
    iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 64 64'%3E%3Cpath fill='white' d='M55.407.547H8.833C4.271.547.559 4.26.559 8.824v46.568c0 4.565 3.713 8.279 8.274 8.279h46.574c4.564 0 8.276-3.713 8.276-8.279V8.824c0-4.564-3.712-8.277-8.276-8.277M35.358 16.592a6.583 6.583 0 0 1 6.584 6.584c0 3.634-2.949 6.586-6.584 6.586s-6.584-2.952-6.584-6.586a6.583 6.583 0 0 1 6.584-6.584M8.191 18.675v-5.269h5.269V8.137h5.269v5.269h5.269v5.269h-5.269v5.268H13.46v-5.268zM52.64 53.058c-.488 0-.957-.095-1.385-.267l-5.862-1.631v5.843H25.044V51.16l-5.86 1.631a3.74 3.74 0 0 1-1.385.267a3.755 3.755 0 0 1-3.112-5.853l9.4-12.889c1.02-1.398 2.445-2.955 5.45-2.955H40.9c3.004 0 4.43 1.558 5.449 2.955l9.399 12.889a3.74 3.74 0 0 1 .642 2.099a3.757 3.757 0 0 1-3.752 3.754z'/%3E%3Cpath fill='white' d='M25.043 35.868v7.816l.712-.24c5.242-1.545 7.281 5.376 2.04 6.918l-.168.047l7.592 2.513l7.593-2.513l-.171-.047c-5.238-1.542-3.199-8.463 2.039-6.918l.716.24v-7.816l-10.177 3.325z'/%3E%3C/svg%3E",
    title: 'Danışma + Reçete 100 zł',
  },
  {
    
      iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2rem' height='2rem' viewBox='0 0 384 512'%3E%3Cpath fill='white' d='M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48M192 40c13.3 0 24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24m96 304c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8zm0-192c0 4.4-3.6 8-8 8H104c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h176c4.4 0 8 3.6 8 8z'/%3E%3C/svg%3E",
      title: 'Hastalik İzni: Fiyat Değişkendir.',
    
  },
];



export default function Home() {
  pageTitle('Ana Sayfa');
  return (
    <>
      <Hero
        title="Esas olan sağlığı korumaktır."
        subTitle="Size sağlık yolculuğunuzda rehberlik etmek ve en üst düzeyde sağlık hizmetleri sunmak için buradayız. Çünkü biliyoruz ki, sağlık en değerli varlıktır ve her adımda size destek olmaya hazırız!"
        bgUrl="/images/appointments/banner_bg.svg"
        imgUrl="/images/home_1/doktor.svg"
        bannerBtnText="Bize ulaşın"
        infoList={[
          {
            title: 'WhatsApp Türkiye',
            subTitle: '+90 534 019 82 29',
            phone:'+905340198229',
            iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3rem' height='3rem' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28'/%3E%3C/svg%3E",

          },
          {
            title: 'WhatsApp Polonya',
            subTitle: '+48 517 326 010',
            phone:'+48517326010',
            iconUrl: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3rem' height='3rem' viewBox='0 0 24 24'%3E%3Cpath fill='%23000' d='M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07c0 1.22.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28'/%3E%3C/svg%3E",
          },
          
        ]}
        btnText="Contact Us"
        btnUrl="/contact"
      />
      
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Servislerimiz" data={featureListData} />
      </Section>
      {/* End Feature Section */}
        





      {/* Start Banner Section */}
      <Section className="mb-5">
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/saglik.png"
          title="Sağlığınızı İhmal Etmeyin!"
          subTitle="Uzman sağlık ekibimizle tanışın ve sağlığınızı güvence altına almak için bugün bir randevu alın!"
        />
      </Section>


      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2

          sectionTitle="Fiyat Listemiz"
          
          data={awardData}
        />
      </Section>

     
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1 mt-5" >
          <SectionHeading   title={"Bize Ulaşın"} center />

            <ContactForm />
          </div>
        </div>
      </div>





      <Section
        topMd={170}
        topLg={145}
        topXl={90}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="İletişim Bilgileri" />

      </Section>


    </>
  );
}
